import moment from 'moment-timezone';
import { IDiagramNode, IDiagramLink, INodeType } from 'models/project';
import { getProjectNodes } from 'api/project';

export async function loadDiagram(projectId: string) {
  const { data } = await getProjectNodes(projectId);
  const nodes: IDiagramNode[] = [];
  const links: IDiagramLink[] = [];
  for (const [id, node] of Object.entries(data.nodes)) {
    nodes.push({
      id,
      projectId: node.project_id,
      creationTime: moment(node.creation_time),
      description: node.description,
      name: node.name,
      metric: node.metric,
      type: node.type,
      value: node.value,
      manual_value: node.manual_value,
      row: node.row - 1,
      column: node.column - 1,
      nextLinks: new WeakMap<IDiagramLink, IDiagramLink>(),
      prevLinks: new WeakMap<IDiagramLink, IDiagramLink>(),
    })
  }

  for (const [_, value] of Object.entries(data.links)) { // eslint-disable-line
    for (const [_, link] of Object.entries(value)) { // eslint-disable-line
      links.push({
        id: `${link.source_node_id}-${link.target_node_id}`,
        targetNodeId: link.target_node_id,
        sourceNodeId: link.source_node_id,
        formula: link.formula,
        description: link.description,
        calculation_method: link.calculation_method,
        data_source: link.data_source,
        creationTime: moment(link.creation_time),
      })
    }
  }

  for (const link of links) {
    const sourceNode = nodes.find(n => n.id === link.sourceNodeId);
    const targetNode = nodes.find(n => n.id === link.targetNodeId);
    link.nextNode = targetNode;
    link.prevNode = sourceNode;
    sourceNode!.nextLinks.set(link, link);
    targetNode!.prevLinks.set(link, link);
  }
  return { 
    links, 
    nodes, 
    types: Object.keys(INodeType)
  };
}