import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from 'api/account';
import { AppState } from 'store';
import moment from 'moment-timezone';
import { setUserLoadedAction, loadUserInfoAction } from 'store/profile/actions';

export default function useProfile() {
  const dispatch = useDispatch();
  const { info, isLoaded } = useSelector((s: AppState) => s.profile);

  const fetchProfile = useCallback(
    async () => {
      try {
        const { data } = await getProfile();
        dispatch(loadUserInfoAction({ ...data, projects: data.projects.map(p => ({
          ownerId: p.owner_id,
          id: p.id,
          creationTime: moment(p.creation_time),
          info: p.info,
          name: p.name,
          description: p.description,
          tagline: p.tagline,
          website: p.website,
          problem: p.problem,
          solution: p.solution,
          startDate: moment(p.start_date),
          slug: p.slug,
          isDraft: p.is_draft,
        }))}))
      } catch (err) {
        console.error(err);
      }
      dispatch(setUserLoadedAction(true));
    },
    [], // eslint-disable-line
  );

  

  return { fetchProfile, isLoaded, info };
}