import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as ArrowBottomIcon } from 'icons/arrow-bottom.svg';
import { IDiagramNode, IDiagramLink, NodeTypeColumnNamesMap } from 'models/project';
import ContentField from 'components/common/ContentField/ContentField';
import TextInput from 'components/common/TextInput/TextInput';
import { Converter } from 'showdown';

import s from './Sidebar.module.css';


const converter = new Converter();

interface IProps {
  node?: IDiagramNode | null;
  link?: IDiagramLink | null;
  links: any[];
  onClose(): any;
  onNodeChange?(node: IDiagramNode): any;
  onLinkChange?(link: IDiagramLink): any;
  edit?: boolean;
}

export default function Sidebar({ node, link, onClose, edit, links, onNodeChange, onLinkChange }: IProps) {
  const showed = !!node || !!link;
  const [value, setValue] = useState(0);
  const [metric, setMetric] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [formula, setFormula] = useState('');
  const [dataSource, setDataSource] = useState('');
  const [linkDescription, setLinkDescription] = useState('');
  const [calculationMethod, setCalculationMethod] = useState('');
  const [nodeErrors, setNodeErrors] = useState<any>({});
  const [linkErrors, setLinkErrors] = useState<any>({});
  const nameRef = useRef<any>(null);
  const descriptionRef = useRef<any>(null);
  const metricRef = useRef<any>(null);
  const linkDescriptionRef = useRef<any>(null);
  const calculationMethodRef = useRef<any>(null);
  const dataSourceRef = useRef<any>(null);


  useEffect(
    () => {
      window.addEventListener('click', e => {
        // onClose();
      })
    },
    [] // eslint-disable-line
  );

  
  useEffect(
    () => {
      if (showed) {
        document.body.classList.add(s.withSidebar);
        return () => {
          document.body.classList.remove(s.withSidebar);
        }
      }
    },
    [showed],
  );

  useEffect(
    () => {
      if (edit) {
        setNodeErrors({});
        setLinkErrors({});
        if (node) {
          console.log(node.errors);
          setNodeErrors(node.errors || {});
          setValue(node.value || 0);
          setMetric(converter.makeHtml(node.metric));
          if (metricRef.current) {
            metricRef.current.value = converter.makeHtml(node.metric);
          }
          setName(converter.makeHtml(node.name));
          if (nameRef.current) {
            nameRef.current.value = converter.makeHtml(node.name);
          }
          setDescription(converter.makeHtml(node.description));
          if (descriptionRef.current) {
            descriptionRef.current.value = converter.makeHtml(node.description);
          }
          setFormula('');
          setLinkDescription('');
          setCalculationMethod('');
          setDataSource('');
        } else if (link) {
          setLinkErrors(link.errors || {});
          setFormula(link.formula);
          setLinkDescription(link.description);
          if (linkDescriptionRef.current) {
            linkDescriptionRef.current.value = converter.makeHtml(link.description);
          }
          setCalculationMethod(link.calculation_method);
          if (calculationMethodRef.current) {
            calculationMethodRef.current.value = converter.makeHtml(link.calculation_method);
          }
          setDataSource(link.data_source);
          if (dataSourceRef.current) {
            dataSourceRef.current.value = converter.makeHtml(link.data_source);
          }
          setValue(0);
          setMetric('');
          setName('');
          setDescription('');
        }
      } else {
        setValue(0);
        setMetric('');
        setName('');
        setDescription('');
        setFormula('');
        setLinkDescription('');
        setCalculationMethod('');
        setDataSource('');
      }
    },
    [edit, node, link]
  );
  
  if (edit) {
    if (node) {
      const prevLinks = links.filter(l => l.targetNodeId === node.id);
      const calculated = prevLinks.length && prevLinks.some(l => l.formula);
      return (
        <div className={cx(s.wrapper, { [s.showed]: showed })}>
          <div className={s.overlay}  onClick={onClose} />
          <div className={s.inner}>
            <CloseIcon className={s.close} onClick={onClose} />
            <div className={s.inner}>
              <div className={s.title}>
                <TextInput
                  className={s.valueInput}
                  disabled={!!calculated}
                  type={calculated ? 'text' : 'number'} 
                  placeholder="Value"
                  error={nodeErrors.manual_value}
                  value={calculated ? 'calculated' : value}
                  onChange={e => {
                    const value = +e;
                    setValue!(value > 0 ? value : 0);
                  }}
                />
              </div>
              <div className={s.title}>
                <ContentField
                  className={s.content}
                  placeholder="Metric"
                  oneLine html={metric}
                  error={nodeErrors.metric}
                  inputRef={metricRef}
                  onChange={metric => {
                    setMetric(metric);
                  }}
                />
              </div>
              <div className={s.title}>
                <ContentField
                  oneLine
                  html={name}
                  className={s.content}
                  placeholder="Name" 
                  error={nodeErrors.name}
                  inputRef={nameRef}
                  onChange={name => {
                    setName(name);
                  }}
                />
              </div>
              <ContentField
                html={description}
                className={s.content}
                placeholder="Description"
                error={nodeErrors.description}
                inputRef={descriptionRef}
                onChange={description => {
                  setDescription(description);
                }}
              />
              <div className={s.actions}>
                <button 
                  className={s.action} 
                  onClick={() => {
                    const errors: any = {};
                    let nameValue = converter.makeMarkdown(name).trim();
                    let metricValue = converter.makeMarkdown(metric).trim();
                    let descriptionValue = converter.makeMarkdown(description).trim();
                    if (nameValue.length > 100) {
                      errors.name = 'Too long';
                    }
                    if (metricValue.length > 100) {
                      errors.metric = 'Too long';
                    }
                    if (descriptionValue.length > 1000) {
                      errors.description = 'Too long';
                    }
                    if (Object.keys(errors).length > 0) {
                      setNodeErrors(errors);
                      return;
                    }
                    onNodeChange!({ 
                      ...node, 
                      manual_value: value || 0,
                      name: nameValue, 
                      description: descriptionValue, 
                      metric: metricValue,
                      isTouched: true,
                      errors: undefined,
                    });
                  }}
                >Apply</button>
                <button className={s.action} onClick={onClose}>Cancel</button>
              </div>
            </div>
            
          </div>
        </div>
      );
    }

    if (link) {
      return (
        <div className={cx(s.wrapper, { [s.showed]: showed })}>
          <div className={s.overlay} onClick={onClose} />
          <div className={s.inner}>
            <CloseIcon className={s.close} onClick={onClose} />
            <div className={s.inner}>
              <div className={s.title}>
                <TextInput 
                  placeholder="Formula"
                  type="text"
                  value={formula}
                  error={linkErrors.formula}
                  onChange={e => {
                    setFormula!(e);
                  }}
                />
              </div>
              <div className={s.title}>
                <ContentField
                  className={s.content}
                  placeholder="Calculation method"
                  oneLine 
                  html={calculationMethod}
                  error={linkErrors.calculation_method}
                  inputRef={calculationMethodRef}
                  onChange={value => {
                    setCalculationMethod(value);
                  }}
                />
              </div>
              <div className={s.title}>
                <ContentField
                  className={s.content}
                  placeholder="Data source"
                  html={dataSource}
                  error={linkErrors.data_source}
                  inputRef={dataSourceRef}
                  onChange={value => {
                    setDataSource(value);
                  }}
                />
              </div>
              <div className={s.title}>
                <ContentField
                  html={linkDescription}
                  className={s.content}
                  placeholder="Description"
                  error={linkErrors.description}
                  inputRef={linkDescriptionRef}
                  onChange={description => {
                    setLinkDescription(description);
                  }}
                />
              </div>
              <div className={s.actions}>
                <button 
                  className={s.action}
                  onClick={() => {
                    const errors: any = {};
                    const calculationMethodValue = converter.makeMarkdown(calculationMethod).trim();
                    const formulaValue = converter.makeMarkdown(formula).trim();
                    const dataSourceValue = converter.makeMarkdown(dataSource).trim();
                    const descriptionValue = converter.makeMarkdown(linkDescription).trim();
                    if (formulaValue.length && !(/^[a-z0-9+\-*()^\s*]+$/ugi.test(formulaValue))) {
                      errors.formula = 'Invalid formula';
                    }
                    if (calculationMethodValue.length > 10-0) {
                      errors.calculation_method = 'Too long';
                    }
                    if (descriptionValue.length > 1000) {
                      errors.description = 'Too long';
                    }
                    if (dataSourceValue.length > 1000) {
                      errors.data_source = 'Too long';
                    }
                    if (Object.keys(errors).length > 0) {
                      setLinkErrors(errors);
                      return;
                    }
                    onLinkChange!({ 
                      ...link,
                      data_source: dataSourceValue,
                      formula: formulaValue,
                      calculation_method: calculationMethodValue, 
                      description: descriptionValue, 
                      isTouched: true,
                      errors: undefined,
                    });
                  }}
                >Apply</button>
                <button className={s.action} onClick={onClose}>Cancel</button>
              </div>
            </div>
            
          </div>
        </div>
      )
    }
  }
  
  return (
    <div className={cx(s.wrapper, { [s.showed]: showed })}>
      <div className={s.overlay} onClick={onClose} />
      <div className={s.inner}>
        <CloseIcon className={s.close} onClick={onClose} />
        {showed && (
          node ? (
            <div className={s.inner}>
              <div className={s.type}>
                {node.type}
              </div>
              <div className={s.title}>
                <div className={cx('trix-content')} dangerouslySetInnerHTML={{ __html: converter.makeHtml(`${Math.floor(node.manual_value || 0)} ${node.metric} ${node.name}`) }} />
              </div>
              <div className={cx('trix-content', s.content)} dangerouslySetInnerHTML={{ __html: converter.makeHtml(node.description || '') }} />
            </div>
          ) : (
            <div className={s.inner}>
              <div className={s.type}>
                {NodeTypeColumnNamesMap[link!.prevNode!.type] ? NodeTypeColumnNamesMap[link!.prevNode!.type] : link!.prevNode!.type}{` → ${NodeTypeColumnNamesMap[link!.nextNode!.type] ? NodeTypeColumnNamesMap[link!.nextNode!.type] : link!.nextNode!.type}`}
              </div>
              <div className={s.title}>
                <div><div className="trix-content" dangerouslySetInnerHTML={{ __html: converter.makeHtml(link!.prevNode!.name || '') }} /></div>
                <ArrowBottomIcon />
                <div><div className="trix-content" dangerouslySetInnerHTML={{ __html: converter.makeHtml(link!.nextNode!.name || '') }} /></div>
              </div>
              
              {link!.calculation_method && (<div className={s.content}>
                <div className="trix-content" dangerouslySetInnerHTML={{ __html: converter.makeHtml(link!.calculation_method) }} />
              </div>)}
              <div className={s.content}>
                <div className="trix-content" dangerouslySetInnerHTML={{ __html: converter.makeHtml(link!.description || '') }} />
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}