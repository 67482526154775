import React from 'react';
import cx from 'classnames';
import Button from '@material-ui/core/Button';

import s from './Form.module.css';

interface IProps {
  additionalActions?: any;
  error?: string | null;
  okText?: string;
  ok?: any;
  cancelText?: string;
  cancel?: any;
  disabled?: boolean;
  title?: string;
  children?: any;
  className?: string;
  autoComplete?: string;
  noValidate?: boolean;
}

export default function Form({ 
  title, error, okText, ok, cancel, cancelText, disabled, children, className,
  noValidate = true, autoComplete = "off", additionalActions
}: IProps) {
  return (
    <form 
      noValidate={noValidate} 
      autoComplete={autoComplete} 
      className={cx(s.form, { [className!]: !!className })}
    >
      {title && <div className={s.title}>{title}</div>}
      <div className={s.inner}>{children}</div>
      {error && <div className={s.error}>{error}</div>}
      {(ok || cancel || additionalActions) && (
        <div className={s.actions}>
          {ok && (<Button variant="contained" color="primary" disabled={disabled} onClick={ok}>{okText || 'Ok'}</Button>)}
          {cancel && (<Button variant="contained" disabled={disabled} onClick={cancel}>{cancelText || 'Cancel'}</Button>)}
          {additionalActions}
        </div>
      )}
    </form>
  )
}