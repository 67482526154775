import React from 'react';
import cx from 'classnames';

import s from './TextInput.module.css';

interface IProps {
  value: any;
  onChange(value: any): any;
  className?: string;
  wrapperClassName?: string;
  type?: string;
  error?: string;
  disabled?: boolean;
  placeholder?: string;
  pattern?: string;
  title?: string;
}

export default function TextInput({ wrapperClassName, title, value, onChange, className, type, error, disabled, placeholder }: IProps) {
  return (
    <div className={cx(s.wrapper, { [wrapperClassName!]: !!wrapperClassName })}>
      {title && <div className={s.title}>{title}:</div>}
      <input
        disabled={disabled}
        type={type}
        onChange={e => onChange(e.target.value)}
        value={value}
        placeholder={placeholder}
        className={cx(s.input, { [s.error]: !!error, [className!]: !!className })} 
      />
      {error && <div className={s.errorText}>{error}</div>}
    </div>
  );
}