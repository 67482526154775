import React from 'react';
import ContentField from 'components/common/ContentField/ContentField';

import s from './ProjectFooter.module.css';

interface IProps {
  website: string;
  description: string;
}

export default function ProjectFooter({ website, description }: IProps) {
  return (
    <div className={s.wrapper}>
      <div className={s.inner}>
        <div className={s.tagline}>
          <ContentField
            html={description}
            disabled
          />
        </div>
        <div className={s.button}>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={website}
          >Take a look</a>
        </div>
        <div className={s.description}>
          <div>
            The goal of this page is to show<br />
            how the company is making change by it’s activities.<br />
            How the usage of their product and services influences people’s life.
          </div>
          <div>
            The <a href="https://en.wikipedia.org/wiki/Theory_of_change">Theory of Change methodology</a> is used for diagrams.<br />
            We are trying to put together all possible <a href="https://google.com">sources of data</a> to show the realistic big picture.
            <br />
            <br />
            Yours, <a href="https://impctfl.io">impctfl.io</a>.
          </div>
        </div>
      </div>
    </div>
  );
}