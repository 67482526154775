import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, MuiThemeProvider, StylesProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import store from './store';
import './reset.css';
import './fonts/SF/style.css';
import './index.css';
import App from 'components/App/App';
import * as serviceWorker from './serviceWorker';


const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1850,
    }
  },
  typography: {
    fontFamily: `
      'SF Pro', 'Arial', 'Roboto', 'Oxygen';
    `,
  },
  palette: {
    primary: {
      main: '#0094FF',
    },
    secondary: {
      main: '#ff5d5d',
    },
  },
  overrides: {
  },

});

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </MuiThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
