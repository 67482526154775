import React, { useRef, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';
import DatePicker from 'react-datepicker';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment-timezone';
import ContentField from 'components/common/ContentField/ContentField';
import TextField from 'components/common/TextField/TextField';
import TextInput from 'components/common/TextInput/TextInput';
import { IProject } from 'models/project';
import { updateProject, createProject, deleteProject } from 'api/project';
import { Converter } from 'showdown';


import 'react-datepicker/dist/react-datepicker.css';

import s from './ProjectInfo.module.css';

const converter = new Converter();

interface IProps {
  problemHtml: string;
  solutionHtml: string;
  descriptionHtml: string;
  name: string;
  tagline: string;
  website: string;
  slug: string;
  startDate: Date;
  isDraft: boolean;
  onProblemHtmlChange(html: string): any;
  onSolutionHtmlChange(html: string): any;
  onDescriptionHtmlChange(html: string): any;
  onNameChange(value: string): any;
  onTaglineChange(value: string): any;
  onWebsiteChange(value: string): any;
  onIsDraftChange(value: boolean): any;
  onSlugChange(value: string): any;
  onStartDateChange(date: Date): any;
  onSave(data: IProject): any;
  onSaveStart(): any;
  onSaveEnd(): any;
  onDelete?(): any;
  project: IProject;
  edit?: boolean;
  editable?: boolean;
  
}

export default function ProjectInfo(props: IProps) {
  const { 
    project, edit, name, tagline, website, problemHtml, solutionHtml, slug, onProblemHtmlChange, 
    onSolutionHtmlChange, onNameChange, onTaglineChange, onWebsiteChange, onSlugChange, onSave,
    startDate, onStartDateChange, onSaveStart, onSaveEnd, onDescriptionHtmlChange, descriptionHtml,
    isDraft, onIsDraftChange, editable, onDelete,
  } = props;
  const [errors, setErrors] = useState<any>({});
  const nameRef = useRef<any>();

  useLayoutEffect(() => {
    if (edit && nameRef.current) {
      nameRef.current.focus();
    }
  }, [edit]);
  
  return (
    <section className={s.wrapper}>
      <hgroup className={s.header}>
        <h1 className={s.name}>
          <TextField
            value={name}
            placeholder="Name"
            onChange={onNameChange}
            disabled={!edit}
            inputRef={nameRef}
            error={errors.name}
          />
        </h1>
        <h2 className={s.tagline}>
          <TextField
            value={tagline}
            placeholder="Tagline"
            onChange={onTaglineChange}
            disabled={!edit}
            error={errors.tagline}
          />
        </h2>
        <h3 className={s.website}>
          <span role="img" aria-label="icon">🔗</span>
          {edit ? (
            <TextField
              value={website}
              placeholder="Website"
              onChange={onWebsiteChange}
              error={errors.website}
            />
          ): (<a 
            rel="noopener noreferrer"
            target="_blank"
            href={website}
          >{website}</a>)}
        </h3>
        {edit && (
          <div>
            <TextInput
              value={slug}
              title="Project slug"
              placeholder="Project slug"
              onChange={value => {
                onSlugChange(value);
              }}
              error={errors.slug}
              wrapperClassName={s.projectSlug}
              className={s.projectSlugInput}
            />
          </div>
        )}
        {edit && (
          <div className={s.published}>
            <Checkbox 
              checked={!isDraft}
              color="primary" 
              onChange={e => onIsDraftChange(!e.target.checked)}
            />
            <span>Published</span>
          </div>
        )}
        {editable && (
          <div className={s.actions}>
            {!edit ? (
              <Button
                component={Link} 
                variant="contained"
                to={`/${project.slug}/edit`}
                className={s.action}
                color="primary"
              >edit</Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  className={s.action}
                  color="primary"
                  onClick={async () => {
                    const errors: any = {};
                    const nameValue = name.trim();
                    const taglineValue = tagline.trim();
                    const websiteValue = website.trim().toLowerCase();
                    const slugValue = slug.trim().toLowerCase();
                    const problemHtmlValue = converter.makeMarkdown(problemHtml).trim();
                    const solutionHtmlValue = converter.makeMarkdown(solutionHtml).trim();
                    const descriptionHtmlValue = converter.makeMarkdown(descriptionHtml).trim();
                    if (nameValue.length < 3 || nameValue.length > 50) {
                      errors.name = 'Length must be between 3 and 50.';
                    }
                    if (taglineValue.length > 100) {
                      errors.tagline = 'Longer than maximum length 100.';
                    }
                    if (!validator.isURL(websiteValue)) {
                      errors.website = 'Not a valid URL.';
                    }
                    /*if (!validator.isAlpha(slugValue)) {
                      errors.slug = 'String does not match expected pattern';
                    } */if (slugValue.length < 3 || slugValue.length > 50) {
                      errors.slug = 'Length must be between 3 and 50.';
                    }
                    if (problemHtmlValue.length > 5000) {
                      errors.problem = 'Longer than maximum length 5000.';
                    }
                    if (solutionHtmlValue.length > 5000) {
                      errors.solution = 'Longer than maximum length 5000.';
                    }
                    if (descriptionHtmlValue.length > 150) {
                      errors.description = 'Longer than maximum length 150.';
                    }
                    if (Object.keys(errors).length > 0) {
                      setErrors(errors);
                      return;
                    }
                    const payload = {
                      name: nameValue,
                      tagline: taglineValue,
                      website: websiteValue,
                      slug: slugValue,
                      problem: problemHtmlValue,
                      solution: solutionHtmlValue,
                      description: descriptionHtmlValue,
                      start_date: moment(startDate).format('YYYYMMDD'),
                      owner_id: project.ownerId,
                      is_draft: isDraft,
                    }
                    try {
                      onSaveStart();
                      const res = project.id ? (await updateProject(project, payload)) : (await createProject(project, payload));
                      const data = res.data as any;
                      const newProject = {
                        ownerId: data.owner_id,
                        id: data.id,
                        creationTime: moment(data.creation_time),
                        info: data.info,
                        name: data.name,
                        description: data.description,
                        tagline: data.tagline,
                        website: data.website,
                        problem: data.problem,
                        solution: data.solution,
                        startDate: moment(data.start_date),
                        slug: data.slug,
                        isDraft: data.is_draft,
                      }
                      onSave(newProject);
                    } catch(err) {
                      if (err.response && err.response.status === 422) {
                        const data = err.response.data;
                        const errors: any = {}; 
                        Object.keys(data).forEach(key => { 
                          errors[key] = data[key][0];
                        });
                        setErrors(errors);
                      }
                      onSaveEnd();
                    }
                  }}
                >{project.id ? 'save' : 'create'}</Button>
                {project.id && (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={s.action}
                      onClick={async () => {
                        await deleteProject(project);
                        onDelete && onDelete();
                      }}
                    >Delete</Button>
                    <Button
                      component={Link} 
                      variant="contained"
                      to={`/${project.slug}`}
                      className={s.action}
                    >back</Button>
                  </>
                )}
                
              </>
            )}
          </div>
        )}
      </hgroup>
      <section className={s.content}>
        <section className={s.block}>
          <h3 className={s.blockTitle}>Problem</h3>
          <article className={s.blockText}>
            <ContentField
              html={problemHtml}
              onChange={onProblemHtmlChange}
              disabled={!edit}
              error={errors.problem}
            />
          </article>
        </section>
        <section className={s.block}>
          <h3 className={s.blockTitle}>Approach and Solution</h3>
          <article className={s.blockText}>
            <ContentField
              html={solutionHtml}
              onChange={onSolutionHtmlChange}
              disabled={!edit}
              error={errors.solution}
            />
          </article>
        </section>
      </section>
      {edit && <div className={s.description}>
        <ContentField
          html={descriptionHtml}
          onChange={onDescriptionHtmlChange}
          title="Description"
          error={errors.description}
          allowedTags={['b', 'strong', 'sub', 'sup', 'i', 'em', 'del', 'a']}
          allowedActions={['bold', 'sub', 'sup', 'italic', 'strike', 'link']}
        />
      </div>}
      {edit && <div>
        <DatePicker
          className={s.startDatePicker}
          maxDate={new Date()}
          selected={startDate}
          onChange={date => onStartDateChange(date as Date)} 
        />
      </div>}
    </section>
  );
}