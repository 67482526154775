import React from 'react';
import ContentEditable from 'react-contenteditable';
import cx from 'classnames';

import s from './TextField.module.css';

interface IProps {
  value: string;
  placeholder?: string;
  onChange(value: string): any;
  disabled?: boolean;
  inputRef?: any;
  error?: string;
  className?: string;
}

export default function TextField({ placeholder, value, error, onChange, disabled, inputRef, className }: IProps) {
  return (
    <div className={s.wrapper}>
      <ContentEditable 
        tagName="div"
        html={value}
        onChange={(e: any) => onChange!(e.target.value)}
        className={cx(s.input, { [s.error]: !disabled && error, [className!]: !!className })}
        placeholder={placeholder}
        disabled={disabled}
        innerRef={inputRef}
      />
      {!disabled && error && (
        <div className={s.errorText}>
          {error}
        </div>
      )}
    </div>
  )
}