import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { request } from 'api/request';

interface IProfileResponse {
  owner_id: string;
  id: string;
  is_approved: boolean;
  projects: Array<{
    owner_id: string;
    id: string;
    creation_time: string;
    info: string;
    name: string;
    description: string;
    tagline: string;
    website: string;
    problem: string;
    solution: string;
    start_date: string;
    slug: string;
    is_draft:boolean;
  }>
}

export function getProfile(config?: AxiosRequestConfig): Promise<AxiosResponse<IProfileResponse>> {
  return request<IProfileResponse>(
    `${process.env.REACT_APP_API_ENDPOINT}/account/profile/`, 
    'get', 
    undefined,
    config,
  );
}

export function signIn(email: string, password: string, config?: AxiosRequestConfig): Promise<AxiosResponse<IProfileResponse>> {
  return request<IProfileResponse>(
    `${process.env.REACT_APP_API_ENDPOINT}/log_in/`, 
    'post', 
    { email, password },
    config,
  );
}

export function signOut(config?: AxiosRequestConfig): Promise<AxiosResponse<IProfileResponse>> {
  return request<IProfileResponse>(
    `${process.env.REACT_APP_API_ENDPOINT}/log_out/`, 
    'post', 
    undefined,
    config,
  );
}

export function signUp(email: string, password: string, config?: AxiosRequestConfig): Promise<AxiosResponse<IProfileResponse>> {
  return request<IProfileResponse>(
    `${process.env.REACT_APP_API_ENDPOINT}/account/`, 
    'post', 
    { email, password },
    config,
  );
}