import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useRouteMatch, useHistory, Redirect } from 'react-router-dom';
import moment from 'moment-timezone';
import sanitizeHtml from 'sanitize-html';

import 'helpers'; // TODO: use camelize
import ProjectInfo from 'components/project/ProjectInfo/ProjectInfo';
import ProjectDiagram from 'components/project/ProjectDiagram/ProjectDiagram';
import ProjectFooter from 'components/project/ProjectFooter/ProjectFooter';
import { IDiagramNode, IDiagramLink, IProject } from 'models/project';
import { getProjectBySlug, updateNode, deleteNode, createNode, updateLink, createLink, deleteLink } from 'api/project';
import { loadDiagram } from 'helpers/project';
import { Converter } from 'showdown';
import useProfile from 'hooks/useProfile';

import s from './ProjectPage.module.css';

const converter = new Converter();

interface IData {
  nodes: IDiagramNode[];
  links: IDiagramLink[];
  types: string[];
}

export default function ProjectPage() {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState<IProject | null>(null);
  const { project_slug } = useParams() as { [key: string]: string };
  const match = useRouteMatch();
  const edit = match.url.endsWith('/edit');
  // const { projectId } = {projectId: 'a72913c2-a307-4eb1-ba88-f24e8c12ade5'} as { [key: string]: string };
  const [diagramData, setDiagramData] = useState<IData>({ links: [], nodes: [], types: [] });
  const [editDiagramData, setEditDiagramData] = useState<IData>({ links: [], nodes: [], types: [] });
  const diagramDataRef = useRef(diagramData);
  const [problemHtml, setProblemHtml] = useState('');
  const [solutionHtml, setSolutionHtml] = useState('');
  const [descriptionHtml, setDescriptionHtml] = useState('');
  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [tagline, setTagline] = useState('');
  const [slug, setSlug] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [isDraft, setIsDraft] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { info, fetchProfile } = useProfile();

  diagramDataRef.current = diagramData;

  useEffect(
    () => {
      if (project) {
        setProblemHtml(converter.makeHtml(project.problem));
        setSolutionHtml(converter.makeHtml(project.solution));
        setDescriptionHtml(converter.makeHtml(project.description));
        setName(project.name);
        setWebsite(project.website);
        setTagline(project.tagline);
        setSlug(project.slug);
        setIsDraft(project.isDraft);
        setStartDate(project.startDate.toDate());
      }
    },
    [project, edit],
  );

  useEffect(
    () => {
      const onLoad = async () => {
        setLoading(true);
        setProject(null);
        try {
          const { data } = await getProjectBySlug(project_slug);

          setProject({
            ownerId: data.owner_id,
            id: data.id,
            creationTime: moment(data.creation_time),
            info: data.info,
            name: data.name,
            description: data.description,
            tagline: data.tagline,
            website: data.website,
            problem: data.problem,
            solution: data.solution,
            startDate: moment(data.start_date),
            slug: data.slug,
            isDraft: data.is_draft
          });
        } catch (err) {
          console.error(err);
          if (err.response) {
            if (err.response.status === 404) {
              // TODO: display 404 page
            }
          }
        }
        
        setLoading(false);
      };
      onLoad();
    },
    [project_slug],
  );

  const loadDiagramData = useCallback(
    async (projectId: string) => setDiagramData(await loadDiagram(projectId)),
    [],
  );

  useEffect(
    () => {
      setEditDiagramData({ ...diagramData });
    },
    [diagramData],
  );


  useEffect(
    () => {
      setDiagramData({ links: [], nodes: [], types: [] });
      if (project) {
        loadDiagramData(project.id!);
        return () => {
          diagramData.links.forEach(link => {
            link.nextNode = undefined;
            link.prevNode = undefined;
          })
        }
      }
    },
    [project], // eslint-disable-line
  );

  useEffect(
    () => {
      if (project && !edit) {
        // const ws = new WebSocket(`${process.env.REACT_APP_WS_ENDPOINT}/project/${project.id}/ws_get_model_updates`);
        // ws.onmessage = (event: any) => {
        //   const data: any = JSON.parse(event.data);
        //   if (data.event_type === 'node_update') {
        //     const nodeData = data.data;
        //     const node = {
        //       id: nodeData.id,
        //       projectId: nodeData.project_id,
        //       creationTime: moment(nodeData.creation_time),
        //       description: nodeData.description,
        //       name: nodeData.name,
        //       metric: nodeData.metric,
        //       type: nodeData.type,
        //       value: nodeData.value,
        //       manual_value: nodeData.manual_value,
        //       row: nodeData.row - 1,
        //       column: nodeData.column - 1,
        //       nextLinks: new WeakMap<IDiagramLink, IDiagramLink>(),
        //       prevLinks: new WeakMap<IDiagramLink, IDiagramLink>(),
        //     };
        //     const index = diagramDataRef.current!.nodes.findIndex(n => n.id === node.id);
        //     if (index !== -1) {
        //       const prevNode = diagramDataRef.current!.nodes[index];
        //       diagramDataRef.current!.links.forEach((link: any) => {
        //         if (link.nextNode === prevNode) {
        //           link.nextNode = node;
        //           node!.prevLinks.set(link, link);
        //         }
        //         if (link.prevNode === prevNode) {
        //           link.prevNode = node;
        //           node!.nextLinks.set(link, link);
        //         }
        //       });
        //       diagramDataRef.current!.nodes[index] = node;
        //     }
        //     setDiagramData({ 
        //       nodes: [...diagramDataRef.current!.nodes], 
        //       links: [...diagramDataRef.current!.links],
        //       types: [...diagramDataRef.current!.types]
        //     });
        //   } else if (data.event_type === 'model_update') {
        //     loadDiagramData(project.id);
        //   }

        // }
        // return () => {
        //   ws.close();
        // };
      }
      if (edit) {
        setEditDiagramData({ ...diagramData });
      }
    },
    [project, edit], // eslint-disable-line
  );

  useEffect(
    () => {
      if (startDate === null) {
        setStartDate(new Date());
      }
    },
    [startDate]
  );
  
  if (loading) {
    return null;
  }
  if (project && edit && (!info || info.id !== project.ownerId)) {
    return <Redirect to={`/${project.slug}`} />
  }

  if (!project) {
    return (<div className={s.page404}>Page not found</div>);
  }

  return (
    <div className={s.wrapper}>
      <ProjectInfo
        editable={!!(info && info.id === project.ownerId)}
        problemHtml={problemHtml}
        name={name}
        solutionHtml={solutionHtml}
        descriptionHtml={descriptionHtml}
        slug={slug}
        website={website}
        tagline={tagline}
        isDraft={isDraft}
        onIsDraftChange={value => {
          setIsDraft(value);
        }}
        onProblemHtmlChange={(value: string) => {
          setProblemHtml(value);
        }}
        onSolutionHtmlChange={(value: string) => {
          setSolutionHtml(value);
        }}
        onDescriptionHtmlChange={(value: string) => {
          setDescriptionHtml(value);
        }}
        onNameChange={(value: string) => {
          setName(sanitizeHtml(value,  { allowedTags: [] }));
        }}
        onTaglineChange={(value: string) => {
          setTagline(sanitizeHtml(value,  { allowedTags: [] }));
        }}
        
        onWebsiteChange={(value: string) => {
          setWebsite(sanitizeHtml(value,  { allowedTags: [] }));
        }}
        onSlugChange={(value: string) => {
          setSlug(sanitizeHtml(value,  { allowedTags: [] }));
        }}
        startDate={startDate}
        onStartDateChange={date => setStartDate(date)}
        project={project}
        edit={edit}
        onSaveStart={() => setIsLoading(true)}
        onSaveEnd={() => setIsLoading(false)}
        onDelete={async () => {
          await fetchProfile();
          history.replace('/');
        }}
        onSave={async data => {
          // setLoading(true);
          let hasErrors = false;
          try {         
            await Promise.all(
              editDiagramData.nodes.filter(n => !n.isDeleted && n.isNew).map(n => {
                return new Promise(async resolve => {
                  try {
                    const { data: { id } } = await createNode({
                      manual_value: n.manual_value,
                      description: n.description,
                      type: n.type,
                      column: n.column + 1,
                      name: n.name,
                      metric: n.metric,
                      value: n.value,
                      row: n.row + 1,
                      project_id: data.id,
                    });
                    n.id = id;
                    n.isNew = false;
                    n.isTouched = false;
                    editDiagramData
                      .links
                      .filter(l => l.sourceNodeId === n.id)
                      .forEach(l => l.sourceNodeId = id);
                    editDiagramData
                      .links
                      .filter(l => l.targetNodeId === n.id)
                      .forEach(l => l.targetNodeId = id);
                  } catch(err) {
                    if (err.response && err.response.status === 422) {
                      hasErrors = true;
                      n.errors = err.response.data;
                    }
                  }
                  resolve(true);
                });
              
              }),
            );
            await Promise.all(
              editDiagramData.nodes.filter(n => n.isDeleted && !n.isNew && n.isTouched).map(n => {
                return new Promise(async resolve => {
                  try {
                    await deleteNode(n);
                    n.isTouched = false;
                  } catch (err) {
                    console.error(err);
                  }
                  resolve(true);
                })
              }),
            );
            await Promise.all(
              editDiagramData.nodes.filter(n => !n.isDeleted && !n.isNew && n.isTouched).map(n => {
                return new Promise(async resolve => {
                  try {
                    await updateNode({
                      id: n.id,
                      manual_value: n.manual_value,
                      project_id: n.projectId,
                      description: n.description,
                      type: n.type,
                      column: n.column + 1,
                      name: n.name,
                      metric: n.metric,
                      value: n.value,
                      row: n.row + 1,
                    });
                    n.isTouched = false;
                  } catch(err) {
                    if (err.response && err.response.status === 422) {
                      hasErrors = true;
                      n.errors = err.response.data;
                    }
                  }
                  resolve(true);
                });
              }),
            );

            await Promise.all(
              editDiagramData.links.filter(l => !l.isDeleted && l.isNew).map(l => {
                return new Promise(async resolve => {
                  try {
                    await createLink({
                      calculation_method: l.calculation_method,
                      description: l.description,
                      formula: l.formula,
                      data_source: l.data_source,
                      source_node_id: l.sourceNodeId,
                      target_node_id: l.targetNodeId
                    });
                    l.isNew = false;
                    l.isTouched = false;
                  } catch(err) {
                    if (err.response && err.response.status === 422) {
                      hasErrors = true;
                      l.errors = err.response.data;
                    }
                  }
                  resolve(true);
                });
              }),
            );

            await Promise.all(
              editDiagramData.links.filter(l => l.isDeleted && !l.isNew && l.isTouched).map(l => {
                return new Promise(async resolve => {
                  try {
                    await deleteLink(
                      {
                        source_node_id: l.sourceNodeId,
                        target_node_id: l.targetNodeId
                      }
                    );
                    l.isTouched = false;
                  } catch(err) {
                    console.error(err);
                  }
                  resolve(true);
                });
              }),
            );
            await Promise.all(
              editDiagramData.links.filter(l => !l.isDeleted && !l.isNew && l.isTouched).map(l => {
                return new Promise(async resolve => {
                  try {
                    await updateLink({
                      calculation_method: l.calculation_method,
                      description: l.description,
                      formula: l.formula,
                      data_source: l.data_source,
                      source_node_id: l.sourceNodeId,
                      target_node_id: l.targetNodeId
                    });
                    l.isTouched = false;
                  } catch(err) {
                    if (err.response && err.response.status === 422) {
                      hasErrors = true;
                      l.errors = err.response.data;
                    }
                  }
                  resolve(true);
                });
              }),
            );

            if (hasErrors) {
              setEditDiagramData({
                nodes: editDiagramData.nodes.map(n => ({ ...n })),
                links: editDiagramData.links.map(n => ({ ...n })),
                types: editDiagramData.types,
              });
              setIsLoading(false);
              return;
            }

            const { data: resData } = await getProjectBySlug(data.slug);
  
            setProject({
              ownerId: resData.owner_id,
              id: resData.id,
              creationTime: moment(resData.creation_time),
              info: resData.info,
              name: resData.name,
              description: resData.description,
              tagline: resData.tagline,
              website: resData.website,
              problem: resData.problem,
              solution: resData.solution,
              startDate: moment(resData.start_date),
              slug: resData.slug,
              isDraft: resData.is_draft,
            });

            history.push(`/${data.slug}`);
            setIsLoading(false);
            fetchProfile();
          } catch (err) {
            setIsLoading(false);
            console.error(err);
            if (err.response) {
              if (err.response.status === 404) {
                // TODO: display 404 page
              }
            }
          }
          
          // setLoading(false);
        }}
      />
      <ProjectDiagram
        startDate={project.startDate.format('MMMM D, YYYY')}
        data={edit ? editDiagramData : diagramData}
        onDataChange={setEditDiagramData}
        edit={edit}
      />
      <ProjectFooter website={website} description={descriptionHtml} />
      {isLoading && <div className={s.loading}><div className={s.loader}><div /><div /><div /><div /></div></div>}
    </div>
  );
}