import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import cx from 'classnames';
import ExitToApp from '@material-ui/icons/ExitToApp';
import List from '@material-ui/icons/List';

import { useDispatch } from 'react-redux';
import { logOutAction } from 'store/actions';
import { signOut } from 'api/account';
import useProfile from 'hooks/useProfile';

import s from './ProfileHeader.module.css';

export default function ProfileHeader() {
  
  const dispatch = useDispatch();
  const { info } = useProfile();
  const [displayMenu, setDisplayMenu] = useState(false);
  const el = useRef<any>(null);

  useEffect(
    () => {
      const onClick = (e: any) => {
        if (!el!.current!.contains(e.target)) {
          setDisplayMenu(false);
        }
      }
      document.addEventListener('click', onClick);
    },
    []
  )
  
  return (
    <div className={s.wrapper}>
      <List 
        ref={el}
        className={s.projects} 
        onClick={e => { 
          e.preventDefault();
          e.stopPropagation();
          setDisplayMenu(true);
        }}
      />
      <ExitToApp 
        className={s.exit}
        onClick={async e => {
          e.preventDefault();
          e.stopPropagation();
          await signOut();
          window.localStorage.removeItem('newProject');
          dispatch(logOutAction())
        }}
      />
      {displayMenu && (
        <div className={s.menu}>
          {info!.projects.map(p => (
            <Link title={p.name} to={`/${p.slug}`} className={s.menuRow} key={p.id}>{p.name}</Link>
          ))}
          <Link to="/create" className={cx(s.menuRow, s.menuRowNew)}>+ Add project</Link>
        </div>
      )}
      
    </div>
  )
}