import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { request } from 'api/request';
import { IProject } from 'models/project';


interface IProjectResponse {
  owner_id: string;
  id: string;
  creation_time: string;
  info: string;
  name: string;
  description: string;
  tagline: string;
  website: string;
  problem: string;
  solution: string;
  start_date: string;
  slug: string;
  is_draft: boolean;
}

export function getProject(id: string, config?: AxiosRequestConfig): Promise<AxiosResponse<IProjectResponse>> {
  return request<IProjectResponse>(
    `${process.env.REACT_APP_API_ENDPOINT}/project/${id}`, 
    'get', 
    undefined,
    config,
  );
}

export function getProjectBySlug(slug: string, config?: AxiosRequestConfig): Promise<AxiosResponse<IProjectResponse>> {
  return request<IProjectResponse>(
    `${process.env.REACT_APP_API_ENDPOINT}/project_by_slug/${slug}`, 
    'get', 
    undefined,
    config,
  );
}

interface IProjectNodesResponse {
  links: {
    [key: string]: {
      [key: string]: {
        formula: string,
        description: string,
        creation_time: string,
        source_node_id: string,
        target_node_id: string,
        data_source: string,
        calculation_method: string,
      }
    }
  }
  nodes: {
    [key: string]: {
      creation_time: string;
      description: string;
      id: string;
      name: string;
      metric: string;
      type: string;
      project_id: string;
      row: number;
      column: number;
      value?: number;
      manual_value?: number;
    }
  }
}

export function getProjectNodes(id: string, config?: AxiosRequestConfig): Promise<AxiosResponse<IProjectNodesResponse>> {
  return request<IProjectNodesResponse>(`${process.env.REACT_APP_API_ENDPOINT}/project/${id}/model`, 'get', undefined, config);
}

export function updateProject(project: IProject, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<IProjectResponse>> {
  return request<IProjectResponse>(
    `${process.env.REACT_APP_API_ENDPOINT}/project/${project.slug}`, 
    'put', 
    data,
    config,
  );
}
export function createProject(project: IProject, data: any, config?: AxiosRequestConfig): Promise<AxiosResponse<IProjectResponse>> {
  return request<IProjectResponse>(
    `${process.env.REACT_APP_API_ENDPOINT}/project/`, 
    'post', 
    data,
    config,
  );
}

export function deleteProject(project: IProject, config?: AxiosRequestConfig): Promise<AxiosResponse<IProjectResponse>> {
  return request<IProjectResponse>(
    `${process.env.REACT_APP_API_ENDPOINT}/project/${project.id}`, 
    'delete', 
    undefined,
    config,
  );
}

export function updateNode(node: any, config?: AxiosRequestConfig): Promise<AxiosResponse<IProjectResponse>> {
  return request<IProjectResponse>(
    `${process.env.REACT_APP_API_ENDPOINT}/project_model_node/${node.id}`, 
    'put', 
    node,
    config,
  );
}

export function createNode(node: any, config?: AxiosRequestConfig): Promise<AxiosResponse<IProjectResponse>> {
  return request<IProjectResponse>(
    `${process.env.REACT_APP_API_ENDPOINT}/project_model_node/`, 
    'post', 
    node,
    config,
  );
}


export function deleteNode(node: any, config?: AxiosRequestConfig): Promise<AxiosResponse<IProjectResponse>> {
  return request<IProjectResponse>(
    `${process.env.REACT_APP_API_ENDPOINT}/project_model_node/${node.id}`, 
    'delete', 
    undefined,
    config,
  );
}


export function updateLink(link: any, config?: AxiosRequestConfig): Promise<AxiosResponse<IProjectResponse>> {
  return request<IProjectResponse>(
    `${process.env.REACT_APP_API_ENDPOINT}/project_model_node_link/${link.source_node_id}/${link.target_node_id}`, 
    'put', 
    link,
    config,
  );
}

export function createLink(link: any, config?: AxiosRequestConfig): Promise<AxiosResponse<IProjectResponse>> {
  return request<IProjectResponse>(
    `${process.env.REACT_APP_API_ENDPOINT}/project_model_node_link/`, 
    'post', 
    link,
    config,
  );
}


export function deleteLink(link: any, config?: AxiosRequestConfig): Promise<AxiosResponse<IProjectResponse>> {
  return request<IProjectResponse>(
    `${process.env.REACT_APP_API_ENDPOINT}/project_model_node_link/${link.source_node_id}/${link.target_node_id}`, 
    'delete', 
    undefined,
    config,
  );
}