import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';
import { IDiagramNode, IDiagramLink, NodeTypeColumnNamesMap } from 'models/project';
import Sidebar from 'components/project/Sidebar/Sidebar';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { Converter } from 'showdown';

import s from './ProjectDiagram.module.css';

interface IProps {
  data: IData;
  startDate: string;
  onDataChange(data: IData): any;
  edit: boolean;
}


interface IData {
  nodes: IDiagramNode[];
  links: IDiagramLink[];
  types: string[];
}

const converter = new Converter();

export default function ProjectDiagram({ data, startDate, edit, onDataChange }: IProps) {
  const [arrows, setArrows] = useState<any[]>([]);
  const [selectedNode, setSelectedNode] = useState<IDiagramNode | null>(null);
  const [selectedLink, setSelectedLink] = useState<any | null>(null);
  const diagramRef = useRef<HTMLDivElement>(null);
  const [draggedNode, setDraggedNode] = useState<IDiagramNode | null>(null);
  
  const [newArrowData, setNewArrowData] 
    = useState<{ sourceId?: string, targetId?: string, sourceType?: string, targetType?: string }>({});

  const draggedNodeRef = useRef<any>(null);
  draggedNodeRef.current = draggedNode;
  console.log(data);
  useEffect(
    () => {
      const onLoad = async () => {
        setTimeout(
          () => {
            if (diagramRef.current) {
              const elem = diagramRef.current.querySelector(`.${s.node}`);
              if (elem) {
                const arrows: any[] = [];
                const offsetWidth = (elem as HTMLElement).offsetWidth;
                data.links.filter(l => !l.isDeleted).forEach(link => {
                  const sourceElem = document.getElementById(link.sourceNodeId);
                  const targetElem = document.getElementById(link.targetNodeId);
                  if (sourceElem && targetElem) {
                    const diffSourceTop = sourceElem.offsetTop;
                    const diffTargetTop = targetElem.offsetTop;
                    const width = targetElem.offsetLeft - sourceElem.offsetLeft - offsetWidth;
                    const direction = diffTargetTop - diffSourceTop > 0 ? 1 : -1;
                    const height = Math.abs(diffTargetTop - diffSourceTop);
                    
                    if (width > 0) {
                      const length = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
                      const angleInRad = Math.acos(width / length);
                      const angle = angleInRad * 180 / Math.PI * direction;
                      arrows.push({
                        id: link.id,
                        top: diffSourceTop + 50,
                        left: sourceElem.offsetLeft + offsetWidth,
                        errors: link.errors,
                        length,
                        angle,
                        link,
                        height,
                        angleInRad,
                      })
                    }
                  }
                });
                setArrows(arrows);
              }
            }
          },
          100,
        );
      }
      onLoad();
    },
    [data, edit], // eslint-disable-line
  );

  const maxIndex = useMemo(
    () => data.nodes.length > 0 ? Math.max(...data.nodes.filter(n => !n.isDeleted).map(n => n.row)) : 0,
    [data],
  );

  useEffect(
    () => {
      const { sourceId, targetId } = newArrowData;
      if (sourceId && targetId) {
        const nextNode = data.nodes.find(n => n.id === targetId);
        const prevNode = data.nodes.find(n => n.id === sourceId);
        const newLink: IDiagramLink = {
          id: `${sourceId}-${targetId}`,
          targetNodeId: targetId,
          sourceNodeId: sourceId,
          formula: '',
          description: '',
          calculation_method: '',
          data_source: '',
          prevNode,
          nextNode,
          isNew: true,
        };
        data.links = [...data.links, newLink];
        setNewArrowData({});
        onDataChange({ ...data });
      }
    },
    [newArrowData] // eslint-disable-line
  );


  const renderColumnNodes = useCallback(
    (type: string, typeIndex: number) => {
      const nodes = data.nodes.filter((n: IDiagramNode) => !n.isDeleted && n.type === type);
      const add = (index?: number) => {
        let row = index;
        if (row === undefined) {
          data.nodes.forEach(node => {
            node.row += 1;
          });
          row = 0;
        }
        const newNode: IDiagramNode = {
          id: uuid(),
          type,
          name: 'New node',
          description: '',
          metric: '',
          row,
          nextLinks: new WeakMap<IDiagramLink, IDiagramLink>(),
          prevLinks: new WeakMap<IDiagramLink, IDiagramLink>(),
          column: 0,
          manual_value: 0,
          value: 0,
          isNew: true,
        }
        onDataChange({ ...data, nodes: [...data.nodes, newNode] });
      }
      const elems = Array.from(Array(maxIndex + (edit ? 2 : 1)).keys()).map(index => {
        const node = nodes.find(n => n.row === index);
        if (node) {
          const prevLinks = data.links.filter(l => l.targetNodeId === node.id);
          const calculated = prevLinks.length && prevLinks.some(l => l.formula);
          return (
            <div 
              key={node.id}
              className={cx(s.node, { [s.errorNode]: !!node.errors, [s.selectedNode]: node === selectedNode } )} 
              draggable={edit}
              id={node.id}
              onClick={e => {
                e.stopPropagation();
                setSelectedLink(null);
                setSelectedNode(node);
              }}
              onDragStart={e => {
                setDraggedNode(node);
              }}
              onDragEnd={e => {
                setDraggedNode(null);
              }}
            >
              <div className={s.nodeValue}>
                {(calculated && edit) ? <div className={s.nodeValueCalculated}>Calculated</div> : Math.floor(node.value || 0)}
              </div>
              <div
                className={cx(s.nodeLabel, 'trix-content')}
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(`${node.metric} ${node.name}`) }} 
              />
              {edit && (
                <CloseIcon
                  onClick={() => {
                    data.nodes = data.nodes.filter(n => n !== node);
                    const diffIndex = Math.min(...data.nodes.map(n => n.row));
                    if (diffIndex > 0) {
                      data.nodes = data.nodes.map(n => ({ ...n, row: n.row - diffIndex, isTouched: true }));
                    }
                    const links = data.links.filter(l => l.sourceNodeId === node.id || l.targetNodeId === node.id);
                    // data.links = data.links.filter(l => !links.includes(l));
                    links.forEach(link => {
                      link.nextNode = undefined;
                      link.prevNode = undefined;
                      link.isDeleted = true;
                      link.isTouched = true;
                    });
                    data.nodes.push({ ...node, isDeleted: true, isTouched: true });
                    if (selectedNode && selectedNode.id === node.id) {
                      setSelectedNode(null);
                    }
                    onDataChange({ ...data });
                  }}
                  className={s.nodeRemove} 
                />
              )}
              {edit 
                && typeIndex > 0 
                && (!newArrowData.targetId || newArrowData.targetId === node.id) 
                && (!newArrowData.sourceId || data.types.indexOf(newArrowData.sourceType!) < typeIndex) 
                && (!newArrowData.sourceId || !data.links.find(l => l.sourceNodeId === newArrowData.sourceId && l.targetNodeId === node.id))
                && 
              (
                <div
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (newArrowData.targetId === node.id) {
                      setNewArrowData({ ...newArrowData, targetId: undefined, targetType: undefined });
                    } else {
                      setNewArrowData({ ...newArrowData, targetId: node.id, targetType: type });
                    }
                  }}
                  className={cx(s.createArrow, s.createArrowTarget, { [s.createArrowSelected]: newArrowData.targetId === node.id })}
                >+</div>
              )}
              {edit 
                && typeIndex < data.types.length - 1 
                && (!newArrowData.sourceId || newArrowData.sourceId === node.id) 
                && (!newArrowData.targetId || data.types.indexOf(newArrowData.targetType!) > typeIndex) 
                && (!newArrowData.targetId || !data.links.find(l => l.targetNodeId === newArrowData.targetId && l.sourceNodeId === node.id))
                && 
              (
                <div 
                  className={cx(s.createArrow, s.createArrowSource, { [s.createArrowSelected]: newArrowData.sourceId === node.id })}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (newArrowData.sourceId === node.id) {
                      setNewArrowData({ ...newArrowData, sourceId: undefined, sourceType: undefined });
                    } else {
                      setNewArrowData({ ...newArrowData, sourceId: node.id, sourceType: type });
                    }
                  }}
                >+</div>
              )}
              
            </div>
          );
        }
        return (
          <div 
            key={index} 
            onClick={() => edit && add(index)} 
            className={cx(s.emptyNode, { [s.createNode]: edit })}
            onDragOver={(e)=> {
              e.preventDefault();
            }}
            onDragEnter={e => {
              if (draggedNodeRef.current && draggedNodeRef.current.type === type) {
                (e.target as HTMLElement).classList.add(s.dragOver);
              }
            }}
            onDragLeave={e => {
              (e.target as HTMLElement).classList.remove(s.dragOver);
            }}
            onDrop={(e) => {
              if (draggedNodeRef.current) {
                const nodeIndex = data.nodes.findIndex(n => n.id === draggedNodeRef.current.id);
                if (nodeIndex !== -1) {
                  data.nodes[nodeIndex] = { ...data.nodes[nodeIndex], row: index, isTouched: true };
                  onDataChange({ ...data, nodes: [...data.nodes] });
                }
              }
              (e.target as HTMLElement).classList.remove(s.dragOver);
            }}
          >
            {edit && <div>+</div>}
          </div>
        )
      });
      if (edit) {
        return [
          <div 
            onClick={() => add()} key={-1} className={cx(s.emptyNode, { [s.createNode]: edit })}
            onDragOver={(e)=> {
              e.preventDefault();
            }}
            onDragEnter={e => {
              if (draggedNodeRef.current && draggedNodeRef.current.type === type) {
                (e.target as HTMLElement).classList.add(s.dragOver);
              }
            }}
            onDragLeave={e => {
              (e.target as HTMLElement).classList.remove(s.dragOver);
            }}
            onDrop={(e) => {
              if (draggedNodeRef.current) {
                data.nodes = data.nodes.map(n => {
                  if (n.id === draggedNodeRef.current.id) {
                    return { ...n, row: 0, isTouched: true, };
                  }
                  return { ...n, row: n.row + 1, isTouched: true }
                })
                onDataChange({ ...data, nodes: [...data.nodes] });
              }
              (e.target as HTMLElement).classList.remove(s.dragOver);
            }}
          >
            <div>+</div>
          </div>, 
          ...elems,
        ];
      }
      return elems;
    },
    [data, selectedNode, maxIndex, edit, onDataChange, newArrowData],
  );

  useEffect(
    () => {
      if (selectedNode) {
        const node = data.nodes.find(n => !n.isDeleted && n.id === selectedNode.id);
        setSelectedNode(node || null);
      }
    },
    [data], // eslint-disable-line
  );

  useEffect(
    () => {
      if (selectedLink) {
        const link = arrows.find(l => l.id === selectedLink.id);
        setSelectedLink(link ? link.link : null);
      }
    },
    [arrows], // eslint-disable-line
  );

  return (
    <div className={s.wrapper}>
      {!edit && <div className={s.startDate}>Since {startDate}:</div>}
      <div className={s.diagram} ref={diagramRef}>
        <div className={s.nodes}>
          {data.types.map((t: string, i: number) => (
            <div key={t} className={s.column}>
              <div className={s.columnName}>{NodeTypeColumnNamesMap[t] ? NodeTypeColumnNamesMap[t] : t}</div>
              <div className={s.columnNodes}>
                {renderColumnNodes(t, i)}
              </div>
            </div>
          ))}
        </div>
        {arrows && arrows.map((arrow: any) => {
          const { id, top, left, length, angle, link, angleInRad } = arrow;
          return (
            <div 
              className={cx(s.link, { [s.errorLink]: !!arrow.errors })}
              key={id}
              style={{ 
                left,
                top,
                width: length,
                transform: `rotate(${angle}deg)`,
              }}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedLink({ ...arrow.link, id });
                setSelectedNode(null);
              }}
            >
              <div 
                className={cx(s.linkFill, { [s.selectedLink]: !!(selectedLink && id === selectedLink.id) })}
                style={{ left: 4 / Math.cos(angleInRad), right: 4 / Math.cos(angleInRad) }}
              >
                <div className={s.linkArrow} />
              </div>
              {edit && (
                <CloseIcon 
                  className={cx(s.nodeRemove, s.linkRemove)} 
                  style={{ transform: `rotate(${-angle}deg)` }}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    const current = data.links.find(l => (l.sourceNodeId === link.sourceNodeId) && (l.targetNodeId === link.targetNodeId));
                    if (current) {
                      current.nextNode = undefined;
                      current.prevNode = undefined;
                      current.isDeleted = true;
                      current.isTouched = true;
                      data.links = [...data.links.filter(l => l !== current), { ...current }];
                      if (selectedLink && selectedLink.id === id) {
                        setSelectedLink(null);
                      }
                      onDataChange({ ...data });
                    }
                  }}
                />
              )}
            </div>
          )
        })}
      </div>
      
      <Sidebar
        node={selectedNode}
        link={selectedLink}
        links={data.links}
        edit={edit}
        onNodeChange={node => {
          data.nodes = [...data.nodes.filter(n => n.id !== selectedNode!.id), node];
          onDataChange({ ...data });
          setSelectedNode(null);
        }}
        onLinkChange={link => {
          data.links = [...data.links.filter(l => l.id !== link.id), link];
          onDataChange({ ...data });
          setSelectedLink(null);
        }}
        onClose={() => {
          setSelectedNode(null);
          setSelectedLink(null);
        }}
      />
    </div>
  );
}
