import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import moment from 'moment-timezone';
import sanitizeHtml from 'sanitize-html';
import useProfile from 'hooks/useProfile';

import ProjectInfo from 'components/project/ProjectInfo/ProjectInfo';
import ProjectDiagram from 'components/project/ProjectDiagram/ProjectDiagram';
import ProjectFooter from 'components/project/ProjectFooter/ProjectFooter';
import { IDiagramNode, IDiagramLink, IProject } from 'models/project';
import { getProjectBySlug, updateNode, deleteNode, createNode, updateLink, createLink, deleteLink } from 'api/project';
import { Converter } from 'showdown';

import s from './CreateProject.module.css';

const converter = new Converter();

interface IData {
  nodes: IDiagramNode[];
  links: IDiagramLink[];
  types: string[];
}

export default function ProjectPage() {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState<IProject | null>(null);
  const [editDiagramData, setEditDiagramData] = useState<IData>({ links: [], nodes: [], types: ['INPUT', 'ACTIVITY', 'OUTPUT', 'OUTCOME', 'IMPACT'] });
  const [problemHtml, setProblemHtml] = useState('');
  const [solutionHtml, setSolutionHtml] = useState('');
  const [descriptionHtml, setDescriptionHtml] = useState('');
  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [tagline, setTagline] = useState('');
  const [slug, setSlug] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [isDraft, setIsDraft] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { info, fetchProfile } = useProfile();
  // const { isLoading, setLo} = useLoader();

  useEffect(
    () => {
      const savedData = window.localStorage.getItem('newProject');
      const defaultProject = {
        info: '',
        name: 'New project',
        description: '',
        tagline: '',
        website: '',
        problem: '',
        solution: '',
        slug: '',
        startDate: moment(),
        isDraft: true,
        ownerId: info!.id,
      };
      if (savedData) {
        try {
          const { diagram, ...project } = JSON.parse(savedData);
          setProject({ ...project, ownerId: info!.id, startDate: moment(project.startDate) });
          setEditDiagramData(diagram as IData);
        } catch (err) {
          setProject(defaultProject);
          console.error(err);
        }
        return;
      }
      setProject(defaultProject);
    },
    [], // eslint-disable-line
  );

  useEffect(
    () => {
      let timerId: any = window.setTimeout(
        () => {
          timerId = null;
          const nameValue = name.trim();
          const taglineValue = tagline.trim();
          const websiteValue = website.trim().toLowerCase();
          const slugValue = slug.trim().toLowerCase();
          const problemHtmlValue = converter.makeMarkdown(problemHtml).trim();
          const solutionHtmlValue = converter.makeMarkdown(solutionHtml).trim();
          const descriptionHtmlValue = converter.makeMarkdown(descriptionHtml).trim();
          const item = {
            name: nameValue,
            tagline: taglineValue,
            website: websiteValue,
            slug: slugValue,
            problem: problemHtmlValue,
            solution: solutionHtmlValue,
            description: descriptionHtmlValue,
            start_date: startDate,
            isDraft: isDraft,
            diagram: editDiagramData,
          }
          localStorage.setItem('newProject', JSON.stringify(item));
        },
        1000,
      );
      return () => {
        if (timerId) {
          window.clearTimeout(timerId);
        }
      }
    },
    [problemHtml, solutionHtml, descriptionHtml, name, website, tagline, slug, isDraft, startDate, editDiagramData]
  )

  useEffect(
    () => {
      if (project) {
        setProblemHtml(converter.makeHtml(project.problem));
        setSolutionHtml(converter.makeHtml(project.solution));
        setDescriptionHtml(converter.makeHtml(project.description));
        setName(project.name);
        setWebsite(project.website);
        setTagline(project.tagline);
        setSlug(project.slug);
        setIsDraft(project.isDraft);
        setStartDate(project.startDate.toDate());
        setLoading(false);
      }
    },
    [project],
  );

  useEffect(
    () => {
      if (startDate === null) {
        setStartDate(new Date());
      }
    },
    [startDate]
  );

  if (!info) {
    return <Redirect to="/signin" />;
  }
  
  if (loading) {
    return null;
  }
  
  return (
    <div className={s.wrapper}>
      <ProjectInfo
        editable
        problemHtml={problemHtml}
        name={name}
        solutionHtml={solutionHtml}
        descriptionHtml={descriptionHtml}
        slug={slug}
        website={website}
        tagline={tagline}
        isDraft={isDraft}
        onIsDraftChange={value => {
          setIsDraft(value);
        }}
        onProblemHtmlChange={(value: string) => {
          setProblemHtml(value);
        }}
        onSolutionHtmlChange={(value: string) => {
          setSolutionHtml(value);
        }}
        onDescriptionHtmlChange={(value: string) => {
          setDescriptionHtml(value);
        }}
        onNameChange={(value: string) => {
          setName(sanitizeHtml(value,  { allowedTags: [] }));
        }}
        onTaglineChange={(value: string) => {
          setTagline(sanitizeHtml(value,  { allowedTags: [] }));
        }}
        
        onWebsiteChange={(value: string) => {
          setWebsite(sanitizeHtml(value,  { allowedTags: [] }));
        }}
        onSlugChange={(value: string) => {
          setSlug(sanitizeHtml(value,  { allowedTags: [] }));
        }}
        startDate={startDate}
        onStartDateChange={date => setStartDate(date)}
        project={project!}
        edit
        onSaveStart={() => setIsLoading(true)}
        onSaveEnd={() => setIsLoading(false)}
        onSave={async data => {
          // setLoading(true);
          let hasErrors = false;
          try {         
            await Promise.all(
              editDiagramData.nodes.filter(n => !n.isDeleted && n.isNew).map(n => {
                return new Promise(async resolve => {
                  try {
                    const { data: { id } } = await createNode({
                      manual_value: n.manual_value,
                      description: n.description,
                      type: n.type,
                      column: n.column + 1,
                      name: n.name,
                      metric: n.metric,
                      value: n.value,
                      row: n.row + 1,
                      project_id: data.id,
                    });
                    n.id = id;
                    n.isNew = false;
                    n.isTouched = false;
                    editDiagramData
                      .links
                      .filter(l => l.sourceNodeId === n.id)
                      .forEach(l => l.sourceNodeId = id);
                    editDiagramData
                      .links
                      .filter(l => l.targetNodeId === n.id)
                      .forEach(l => l.targetNodeId = id);
                  } catch(err) {
                    if (err.response && err.response.status === 422) {
                      hasErrors = true;
                      n.errors = err.response.data;
                    }
                  }
                  resolve(true);
                });
              
              }),
            );
            await Promise.all(
              editDiagramData.nodes.filter(n => n.isDeleted && !n.isNew && n.isTouched).map(n => {
                return new Promise(async resolve => {
                  try {
                    await deleteNode(n);
                    n.isTouched = false;
                  } catch (err) {
                    console.error(err);
                  }
                  resolve(true);
                })
              }),
            );
            await Promise.all(
              editDiagramData.nodes.filter(n => !n.isDeleted && !n.isNew && n.isTouched).map(n => {
                return new Promise(async resolve => {
                  try {
                    await updateNode({
                      id: n.id,
                      manual_value: n.manual_value,
                      project_id: n.projectId,
                      description: n.description,
                      type: n.type,
                      column: n.column + 1,
                      name: n.name,
                      metric: n.metric,
                      value: n.value,
                      row: n.row + 1,
                    });
                    n.isTouched = false;
                  } catch(err) {
                    if (err.response && err.response.status === 422) {
                      hasErrors = true;
                      n.errors = err.response.data;
                    }
                  }
                  resolve(true);
                });
              }),
            );

            await Promise.all(
              editDiagramData.links.filter(l => !l.isDeleted && l.isNew).map(l => {
                return new Promise(async resolve => {
                  try {
                    await createLink({
                      calculation_method: l.calculation_method,
                      description: l.description,
                      formula: l.formula,
                      data_source: l.data_source,
                      source_node_id: l.sourceNodeId,
                      target_node_id: l.targetNodeId
                    });
                    l.isNew = false;
                    l.isTouched = false;
                  } catch(err) {
                    if (err.response && err.response.status === 422) {
                      hasErrors = true;
                      l.errors = err.response.data;
                    }
                  }
                  resolve(true);
                });
              }),
            );

            await Promise.all(
              editDiagramData.links.filter(l => l.isDeleted && !l.isNew && l.isTouched).map(l => {
                return new Promise(async resolve => {
                  try {
                    await deleteLink(
                      {
                        source_node_id: l.sourceNodeId,
                        target_node_id: l.targetNodeId
                      }
                    );
                    l.isTouched = false;
                  } catch(err) {
                    console.error(err);
                  }
                  resolve(true);
                });
              }),
            );
            await Promise.all(
              editDiagramData.links.filter(l => !l.isDeleted && !l.isNew && l.isTouched).map(l => {
                return new Promise(async resolve => {
                  try {
                    await updateLink({
                      calculation_method: l.calculation_method,
                      description: l.description,
                      formula: l.formula,
                      data_source: l.data_source,
                      source_node_id: l.sourceNodeId,
                      target_node_id: l.targetNodeId
                    });
                    l.isTouched = false;
                  } catch(err) {
                    if (err.response && err.response.status === 422) {
                      hasErrors = true;
                      l.errors = err.response.data;
                    }
                  }
                  resolve(true);
                });
              }),
            );

            if (hasErrors) {
              setEditDiagramData({
                nodes: editDiagramData.nodes.map(n => ({ ...n })),
                links: editDiagramData.links.map(n => ({ ...n })),
                types: editDiagramData.types,
              });
              setIsLoading(false);
              return;
            }

            const { data: resData } = await getProjectBySlug(data.slug);
  
            setProject({
              ownerId: resData.owner_id,
              id: resData.id,
              creationTime: moment(resData.creation_time),
              info: resData.info,
              name: resData.name,
              description: resData.description,
              tagline: resData.tagline,
              website: resData.website,
              problem: resData.problem,
              solution: resData.solution,
              startDate: moment(resData.start_date),
              slug: resData.slug,
              isDraft: resData.is_draft,
            });
            window.localStorage.removeItem('newProject');
            history.push(`/${data.slug}`);
            setIsLoading(false);
            fetchProfile();
          } catch (err) {
            setIsLoading(false);
            console.error(err);
            if (err.response) {
              if (err.response.status === 404) {
                // TODO: display 404 page
              }
            }
          }
          
          // setLoading(false);
        }}
      />
      <ProjectDiagram
        startDate={project!.startDate.format('MMMM D, YYYY')}
        data={editDiagramData}
        onDataChange={setEditDiagramData}
        edit
      />
      <ProjectFooter website={website} description={descriptionHtml} />
      {isLoading && <div className={s.loading}><div className={s.loader}><div /><div /><div /><div /></div></div>}
    </div>
  );
}