import { Moment } from 'moment-timezone';

export interface IProject {
  ownerId?: string;
  id?: string;
  creationTime?: Moment;
  info: string;
  name: string;
  description: string;
  tagline: string;
  website: string;
  problem: string;
  solution: string;
  slug: string;
  startDate: Moment;
  isDraft: boolean;
}


export enum INodeType {
  INPUT = 'INPUT',
  ACTIVITY = 'ACTIVITY',
  OUTPUT = 'OUTPUT',
  OUTCOME = 'OUTCOME',
  IMPACT = 'IMPACT',
}

export const NodeTypeColumnNamesMap: { [key:string]: string } = {
  INPUT: 'RESOURCES',
  ACTIVITY: 'ACTIVITIES',
  OUTPUT: 'PRODUCT USAGE',
  OUTCOME: 'EFFECTS'
}


export interface IDiagramNode {
  id: string;
  projectId?: string;
  creationTime?: Moment;
  type: string;
  name: string;
  description: string;
  metric: string;
  nextLinks: WeakMap<IDiagramLink, IDiagramLink>;
  prevLinks: WeakMap<IDiagramLink, IDiagramLink>;
  row: number;
  column: number;
  value?: number;
  manual_value?: number;
  isNew?: boolean;
  isDeleted?: boolean;
  isTouched?: boolean;
  errors?: any;
}


export interface IDiagramLink {
  id: string;
  targetNodeId: string;
  sourceNodeId: string;
  formula: string;
  description: string;
  calculation_method: string;
  data_source: string;
  creationTime?: Moment;
  nextNode?: IDiagramNode;
  prevNode?: IDiagramNode;
  isNew?: boolean;
  isDeleted?: boolean;
  isTouched?: boolean;
  errors?: any;
}