import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import validator from 'validator';

import { signIn } from 'api/account';
import Form from 'components/form/Form/Form';
import useProfile from 'hooks/useProfile';

import s from './SignIn.module.css';

export default function SignIn() {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [displayError, setDisplayError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { info, fetchProfile } = useProfile();

  if (info) {
    return <Redirect to='/' />
  }

  return (
    <Form
      title="Sign in"
      error={error}
      additionalActions={[
        <Button
          key="signin"
          variant="contained" 
          color="primary"
          disabled={isLoading}
          onClick={async e => {
            e.preventDefault();
            setError(null);
            setDisplayError(true);
            const hasErrors = (email.length > 0 && !validator.isEmail(email)) || (password.trim().length === 0);
            if (hasErrors) {
              return;
            }
            setIsLoading(true);
            try {
              await signIn(email, password);
              await fetchProfile();
            } catch (err) {
              console.error(err);
              setIsLoading(false);
              if (err.response.status === 403) {
                setError('Incorrect email or password');
              } else if (err.response.status === 500) {
                setError('Internal server error');
              } else {
                setError('Something went wrong');
              }
            }
          }}
        >Sign in</Button>,
        <Button
          key="signup"
          component={Link}
          variant="contained" 
          disabled={isLoading}
          to="/signup"
        >Create account</Button>
      ]}
    >
      <div className={s.row}>
        <TextField
          fullWidth
          type="email"
          error={displayError && (email.length > 0 && !validator.isEmail(email))}
          helperText={displayError && (email.length > 0 && !validator.isEmail(email)) ? 'Invalid email' : ''}
          placeholder="Email"
          variant="outlined"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
            setDisplayError(false);
          }}
        />
      </div>
      <div className={s.row}>
        <TextField
          fullWidth
          type="password"
          placeholder="Password"
          variant="outlined"
          value={password}
          error={displayError && (password.trim().length === 0)}
          helperText={displayError && (password.trim().length === 0) ? 'Empty password' : ''}
          onChange={e => {
            setPassword(e.target.value);
            setDisplayError(false);
          }}
        />
      </div>
    </Form>
  )
}