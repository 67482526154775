import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import validator from 'validator';

import { signUp } from 'api/account';
import Form from 'components/form/Form/Form';
import useProfile from 'hooks/useProfile';

import s from './SignUp.module.css';

export default function SignUp() {
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [displayError, setDisplayError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);

  const { info } = useProfile();

  if (info) {
    return <Redirect to='/' />
  }
  
  return (
    <Form
      title="Sign up"
      error={error}
      additionalActions={[
        <Button
          key="signin"
          variant="contained" 
          color="primary"
          disabled={isLoading}
          onClick={async e => {
            e.preventDefault();
            setError(null);
            setDisplayError(true);
            const hasErrors = (email.length > 0 && !validator.isEmail(email)) || (password.trim().length === 0) || repeatPassword.trim() !== password.trim();
            if (hasErrors) {
              return;
            }
            setIsLoading(true);
            try {
              await signUp(email, password);
              setDisplaySuccess(true);
              setDisplayError(false);
              setEmail('');
              setPassword('');
              setRepeatPassword('');
            } catch (err) {
              console.error(err);
              setIsLoading(false);
              if (err.response.data?.error?.description) {
                setError(err.response.data?.error?.description);
              } else if (err.response.status === 500) {
                setError('Internal server error');
              } else {
                setError('Something went wrong');
              }
            }
            
          }}
        >Create account</Button>,
        <Button
          key="signup"
          component={Link}
          variant="contained" 
          to="/signin"
          disabled={isLoading}
        >Already have an account?</Button>
      ]}
    >
      <div className={s.row}>
        <TextField
          fullWidth
          type="email"
          error={displayError && (email.length > 0 && !validator.isEmail(email))}
          helperText={displayError && (email.length > 0 && !validator.isEmail(email)) ? 'Invalid email' : ' '}
          placeholder="Email"
          variant="outlined"
          value={email}
          onChange={e => {
            setEmail(e.target.value);
            setDisplayError(false);
          }}
        />
      </div>
      <div className={s.row}>
        <TextField
          fullWidth
          type="password"
          placeholder="Password"
          variant="outlined"
          value={password}
          error={displayError && (password.trim().length === 0)}
          helperText={displayError && (password.trim().length === 0) ? 'Empty password' : ''}
          onChange={e => {
            setPassword(e.target.value);
            setDisplayError(false);
          }}
        />
      </div>
      <div className={s.row}>
        <TextField
          fullWidth
          type="password"
          placeholder="Repeat password"
          variant="outlined"
          value={repeatPassword}
          error={displayError && (repeatPassword.trim() !== password.trim())}
          helperText={displayError && (repeatPassword.trim() !== password.trim()) ? 'Passwords do not match.' : ''}
          onChange={e => {
            setRepeatPassword(e.target.value);
            setDisplayError(false);
          }}
        />
      </div>
      <Modal
        open={displaySuccess}
        onClose={() => {
          setDisplaySuccess(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        className={s.modal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={displaySuccess}>
          <div className={s.modalInner}>
            <p className={s.modalText}>An account was successfully created.</p>
            <Button
              className={s.modalBtn}
              variant="contained" 
              color="primary"
              component={Link}
              to="/signin"
              fullWidth
            >Sign in</Button>
          </div>
        </Fade>
      </Modal>
    </Form>
  );
}