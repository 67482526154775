import React, { useMemo, useRef, useEffect } from 'react';
import cx from 'classnames';
import Trix from 'trix';
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";
import sanitizeHtml from 'sanitize-html';

import s from './ContentField.module.css';

Trix.config.textAttributes.sup = { tagName: "sup", inheritable: true };
Trix.config.textAttributes.sub = { tagName: "sub", inheritable: true };
let id = 0;

function getId() {
  return id++;
}
interface IProps {
  html: string;
  inputRef?: any;
  onChange?(e: string): any;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  onBlur?(event: any): any;
  oneLine?: boolean;
  error?: string;
  title?: string;
  allowedActions?: string[];
  allowedTags?: string[];
}

const tags: string[] = ['b', 'strong', 'sub', 'sup', 'i', 'em', 'del', 'br', 'blockquote', 'ul', 'li', 'ol', 'h1', 'a'];
const oneLineTags: string[] = ['b', 'strong', 'sub', 'sup', 'i', 'em', 'del'];

const actions = ['bold', 'sub', 'sup', 'italic', 'strike', 'link', 'heading1', 'quote', 'bullet', 'number'];
const oneLineActions = ['bold', 'sub', 'sup', 'italic', 'strike', 'link'];


export default function ContentField({ allowedTags, allowedActions, title, html, className, onChange, inputRef, disabled, placeholder, onBlur, oneLine, error }: IProps) {
  const id = useMemo(getId, []);
  const internalRef = useRef<any>(null);
  const ref = inputRef || internalRef;
  
  useEffect(
    () => {
      if (ref.current && !ref.current.init) {
        ref.current.init = true;
        const element = ref.current;
        const editor = element.editor;
        const toolbarElement = ref.current.toolbarElement;
        const linkElement = toolbarElement.querySelector("[data-trix-action=link]");
        linkElement.insertAdjacentHTML('afterend', '<button type="button" class="trix-button" data-trix-attribute="sup">SUP</button>');
        linkElement.insertAdjacentHTML('afterend', '<button type="button" class="trix-button" data-trix-attribute="sub">SUB</button>');
        
        let selectedAttributes = new Set();
        const updateSelectedAttributes = () => {
          selectedAttributes = new Set();
          const selectedRange = editor.getSelectedRange();
          if (selectedRange[0] === selectedRange[1]) { selectedRange[1]++ };
          
          const selectedPieces = editor.getDocument().getDocumentAtRange(selectedRange).getPieces();
          selectedPieces.forEach(function(piece: any) {
            Object.keys(piece.getAttributes()).forEach(function(attribute) {
              selectedAttributes.add(attribute);
            });
          });
        }
        
        const enforceExclusiveAttributes = () => {
          if (editor.attributeIsActive("sup") && selectedAttributes.has("sub")) {
            editor.deactivateAttribute("sub");
            updateSelectedAttributes();
          } else if (editor.attributeIsActive("sub") && selectedAttributes.has("sup")) {
            editor.deactivateAttribute("sup");
            updateSelectedAttributes();
          }
        }
        
        updateSelectedAttributes();
        element.addEventListener("trix-selection-change", updateSelectedAttributes);
        element.addEventListener("trix-change", enforceExclusiveAttributes);
        return () => {
          element.removeEventListener("trix-selection-change", updateSelectedAttributes);
          element.removeEventListener("trix-change", enforceExclusiveAttributes);
        }
      }
    },
    [ref, disabled]
  );

  return (
    disabled ? (
      <div className="trix-content" dangerouslySetInnerHTML={{ __html: html }} />
    ) : (
      <div
        data-error={error}
        className={cx(
          s.wrapper,
          {
            [s.edit]: !disabled,
            [s.oneLine]: oneLine,
            [s.error]: !!error,
            [className!]: !!className,
          }
        )}
      >
        {title && <div className={s.title}>{title}:</div>}
        <ReactTrixRTEToolbar
          toolbarId={`toolbar${id}`}
          disableGroupingAction
          toolbarActions={allowedActions || (oneLine ? oneLineActions : actions)}
        />
        <ReactTrixRTEInput
          toolbarId={`toolbar${id}`}
          autofocus
          trixInputRef={ref}
          onChange={(e: any, html: any) => {
            onChange && onChange(sanitizeHtml(html, { allowedTags: (allowedTags || (oneLine ? oneLineTags : tags)) }));
          }} 
          defaultValue={html}
          onFileAccepted={(e: any) => {
            e.preventDefault();
          }}
          
          placeholder={placeholder || 'Start writing here'}
        />
      </div>
    )
  );
}

interface IRenderProps {
  html: string;
  className?: string;
}

export function ContentRenderer({ html, className }: IRenderProps) {
  return (
    <div 
      className={cx(s.content, { [className!]: !!className })}
      dangerouslySetInnerHTML={{ __html: html }} 
    />
  );
}