import { IProject } from 'models/project';
import { ILogOutAction } from 'store/types';

export const LOAD_PROFILE_INFO = 'LOAD_USER_INFO';
export const SET_PROFILE_LOADED = 'SET_USER_LOADED';

export interface IProfileInfo {
  id: string;
  is_approved: boolean;
  projects: IProject[];
}

export interface ILoadUserInfoAction {
  type: typeof LOAD_PROFILE_INFO;
  payload: IProfileInfo | null;
}

export interface ISetUserLoadedAction {
  type: typeof SET_PROFILE_LOADED;
  payload: boolean;
}

export type IProfileActionTypes = ILoadUserInfoAction | ILogOutAction | ISetUserLoadedAction;

export interface IProfileState {
  info: IProfileInfo | null;
  isLoaded: boolean;
}
