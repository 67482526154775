import React, { useEffect } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import s from './App.module.css';
import { ProjectPage, CreateProject } from 'components/project';
import Home from 'components/Home/Home';
import SignIn from 'components/profile/SignIn/SignIn';
import SignUp from 'components/profile/SignUp/SignUp';
import ProfileHeader from 'components/profile/ProfileHeader/ProfileHeader';
import useProfile from 'hooks/useProfile';
import AuthRoute from 'components/common/AuthRoute/AuthRoute'

function App() {
  const { info, isLoaded, fetchProfile } = useProfile();

  useEffect(
    () => {
      fetchProfile();
    },
    [], // eslint-disable-line
  );
  
  if (!isLoaded) {
    return null;
  }

  return (
    <div className={s.app}>
      <Router>
        <div className={s.logo}>
          <a href="https://impctfl.io">Impactful.</a>
        </div>
        {info && <ProfileHeader />}
        <Switch>
          <AuthRoute path="/" exact component={Home} />
          <Route path="/signin" exact component={SignIn} />
          <Route path="/signup" exact component={SignUp} />
          <AuthRoute path="/create" exact component={CreateProject} />
          <Route path="/:project_slug" exact component={ProjectPage} />
          <Route path="/:project_slug/edit" exact component={ProjectPage} />
          {/* <Route path="/" component={ProjectPage}/> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
