import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import useProfile from 'hooks/useProfile';

export default function AuthRoute(props: any) {
  const { info } = useProfile();
  if (!info) {
    return <Redirect to="/signin" />
  }
  return <Route {...props} />;
}