import {
  IProfileState,
  IProfileActionTypes,
  LOAD_PROFILE_INFO,
  SET_PROFILE_LOADED,
} from './types';
import { LOGOUT } from 'store/types';

const initialState: IProfileState = {
  info: null,
  isLoaded: false,
};

export function profileReducer(state = initialState, action: IProfileActionTypes): IProfileState {
  switch (action.type) {
    case LOAD_PROFILE_INFO:
      return { ...state, info: action.payload };
    case SET_PROFILE_LOADED:
      return { ...state, isLoaded: action.payload };
    case LOGOUT:
      return { ...initialState, isLoaded: true };
  }
  return state;
}
