import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import useProfile from 'hooks/useProfile';

import s from './Home.module.css';

export default function Home() {
  
  const { info } = useProfile();

  if (!info) {
    return <Redirect to='/signin' />
  }
  
  if (info.projects.length > 0) {
    return <Redirect to={`/${info.projects[0].slug}`} />
  }
  
  return (
    <div className={s.wrapper}>
      <div className={s.text}>You have no projects.</div>
      <Button
        className={s.btn}
        variant="contained" 
        color="primary"
        component={Link}
        to="/create"
      >Create new project</Button>
    </div>
  );
}