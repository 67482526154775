import { createStore, applyMiddleware, combineReducers, Middleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reduxLogger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { profileReducer } from './profile/reducers';

const rootReducer = combineReducers({
  profile: profileReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

const middlewares: Middleware[] = [
  thunkMiddleware,
];

if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  middlewares.push(reduxLogger);
}

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

export default store;
