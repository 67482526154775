import {
  IProfileActionTypes,
  LOAD_PROFILE_INFO,
  IProfileInfo,
  SET_PROFILE_LOADED,
} from './types';


export function loadUserInfoAction(data: IProfileInfo | null): IProfileActionTypes {
  return {
    type: LOAD_PROFILE_INFO,
    payload: data,
  };
}

export function setUserLoadedAction(loaded: boolean): IProfileActionTypes {
  return {
    type: SET_PROFILE_LOADED,
    payload: loaded,
  };
}
