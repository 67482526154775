import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

type IRequestMethod = 'get' | 'post' | 'patch' | 'delete' | 'put' | 'head';

interface ICancelableRequest<T> extends Promise<AxiosResponse<T>> {
  cancel?: any;
}

const isApiMocked = Boolean(Number(process.env.REACT_APP_MOCK_API));

export function request<T>(url: string, method: IRequestMethod, data?: any, config?: AxiosRequestConfig, mockResult?: T): Promise<AxiosResponse<T>> {
  const source = axios.CancelToken.source();
  let request: ICancelableRequest<T>;
  if (isApiMocked && mockResult) {
    return new Promise((resolve) => {
      resolve({
        data: mockResult,
        status: 200,
        statusText: 'OK',
        headers: null,
        config: {},
      });
    })
  }
  if (method === 'post' || method === 'put' || method === 'patch') {
    request = axios[method]<T>(url, data, { ...config, cancelToken: source.token, withCredentials: true });
  } else {
    request = axios[method]<T>(url, { ...config, cancelToken: source.token, withCredentials: true });
  }
  request.cancel = (reason?: string) => source.cancel(reason || 'Cancel by user');
  return request;
}